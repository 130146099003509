import { Box, Button } from 'grommet';
import React from 'react';


const activeStyle = { background: '#59a4f4', border: 'none', cursor: 'pointer', color: '#fff', paddingTop: 10, paddingBottom: 8, paddingLeft: 20, paddingRight: 20, };
const inactiveStyle = { background: '#EDEDED', border: 'none', cursor: 'pointer', color: '#a2a2a3', paddingTop: 10, paddingBottom: 8, paddingLeft: 20, paddingRight: 20, };
const ToggleButton = ({ groups }) => {

    return (
        <Box direction="row" gap="medium">
            {
                groups.map((button, index) => {

                    let borderStyle = { borderRadius: 10, boxShadow: '3px 3px 3px #c8C8C8' };
                    return (
                        <Button primary
                            key={index + 1}
                            style={button.selected ? Object.assign(borderStyle, activeStyle) : Object.assign(borderStyle, inactiveStyle)}
                            onClick={button.onClick}>
                            {button.label}
                        </Button>
                    )
                })
            }
        </Box>
    )
}

export default ToggleButton;