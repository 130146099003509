import { navigate } from "gatsby-link";
import { Box, ResponsiveContext, Text, TextArea } from 'grommet';
import React from 'react';
import PrimaryButton from '../components/elements/button';
import Layout from '../components/elements/layout';
import ToggleButton from '../components/elements/toggle_button/toggle_button';
import Header from '../components/header';
import salon from '../config/data.json';
import { findRef, getSalon, sendFeedback, updateVisited } from '../services/service';

const STEPS = {
    satisfait: 'satisfait',
    review: 'review',
    feedback: 'feedback',
    end: 'end'
}

class Feedback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            salon,
            step: STEPS.satisfait,
            feedback: {
                id: undefined,
                satisfied: undefined,
                feedback: undefined,
                giving_feedback: undefined
            }
        }
    }

    componentDidMount = () => {
        getSalon()
            .then(res => {
                this.setState({salon: res.data});
            })
        let queryString = {};
        if (typeof window !== `undefined`) {
            queryString = window.location.search;
        }
        const urlParams = new URLSearchParams(queryString);
        let ref = urlParams.get('ref');
        if(ref) {
            updateVisited(ref);
            findRef(ref)
                .then(res => {
                    let refValue = res.data.length > 0 ? res.data[0] : null;
                    if(refValue) {
                        this.setState({feedback: refValue});
                    }
                })
        }
    }

    updateFeedback = (field, value) => {
        let feedback = this.state.feedback;
        feedback[field] = value;
        this.setState({ feedback: Object.assign({}, feedback) });
    }

    submit = () => {
        let feedback = this.state.feedback;
        sendFeedback(feedback);
    }


    render() {
        return (
            <Layout style={{ justifyContent: "center", alignContent: "center" }}>
                <Box width="xlarge" justify="center" align="center" alignSelf="center">
                    <ResponsiveContext.Consumer>
                        {size =>
                            <Box width="xlarge" pad="medium">
                                <Header data={this.state.salon.data} size={size} />

                                <Box width="large" gap="medium" justify="center" align="center" alignSelf="center"
                                    style={{ marginTop: 80 }}
                                >

                                    {this.state.step === STEPS.satisfait &&
                                        <Box gap="medium" justify="center" align="center" alignSelf="center" animation={{ type: 'fadeIn', duration: 2000 }}>
                                            <Text>Bonjour {this.state.feedback.name || ''},<br /><br />Vous avez visité notre salon aujourd'hui et nous adorerions connaître votre avis.<br/><br/>Êtes-vous satisfait de la prestation ?<br /></Text>
                                            <br />
                                            <ToggleButton
                                                groups={
                                                    [true, false].map(value => (
                                                        {
                                                            label: value === true ? 'Oui' : 'Non',
                                                            selected: this.state.feedback.satisfied === value,
                                                            onClick: () => {
                                                                this.updateFeedback('satisfied', value);
                                                                this.setState({ step: value ? STEPS.review : STEPS.feedback })
                                                            }
                                                        }
                                                    ))
                                                }
                                            />
                                        </Box>
                                    }
                                    {this.state.step === STEPS.feedback &&
                                        <Box gap="medium" animation={{ type: 'fadeIn', duration: 2000 }}>
                                            <Text>Pourriez-vous nous dire pour la quelle raison ?</Text>
                                            <TextArea name="value" placeholder="" value={this.state.feedback.feedback} onChange={event => this.updateFeedback('feedback', event.target.value)} />
                                            <br />
                                            <Box justify="end" alignSelf="end">
                                                <PrimaryButton label="Envoyer" onClick={() => {
                                                    this.submit();
                                                    this.setState({ step: STEPS.end });
                                                }} />
                                            </Box>
                                        </Box>
                                    }
                                    {this.state.step === STEPS.review &&
                                        <Box gap="medium" justify="center" align="center" alignSelf="center" animation={{ type: 'fadeIn', duration: 2000 }}>
                                            <Text>Nous sommes en train de construire notre réputation et votre avis pourrait véritablement faire la différence.<br/><br/>Pourriez-vous prendre une minute pour partager votre expérience ?</Text>
                                            <br />
                                            <ToggleButton
                                                groups={
                                                    [true, false].map(value => (
                                                        {
                                                            label: value === true ? 'Oui, je veux bien' : 'Non',
                                                            selected: this.state.feedback.giving_feedback === value,
                                                            onClick: () => {
                                                                this.updateFeedback('giving_feedback', value);
                                                                this.submit();
                                                                if (value) {
                                                                    navigate('https://g.page/r/CXAmJNKYKmNlEAg/review');
                                                                } else {
                                                                    this.setState({ step: STEPS.end });
                                                                }
                                                            }
                                                        }
                                                    ))
                                                }
                                            />

                                        </Box>
                                    }
                                    {this.state.step === STEPS.end &&

                                        <Box gap="medium" animation={{ type: 'fadeIn', duration: 2000 }}>
                                            {this.state.feedback.satisfied === false &&
                                                <Text>
                                                    Merci pour votre commentaire. Grâce à vous, nous pouvons améliorer notre services et vous servir mieux dans l'avernir.
                                                </Text>
                                            }
                                            {this.state.feedback.satisfied === true &&
                                                <Text>
                                                    Merci quand même pour votre temps. Nous espérons de vous revoir bien tôt.<br />Très bonne journée pour vous !!
                                                </Text>
                                            }
                                            <br />
                                            <PrimaryButton label="Fermer" onClick={() => navigate('/')} />
                                        </Box>

                                    }
                                </Box>
                            </Box>
                        }
                    </ResponsiveContext.Consumer>

                    <Box style={{ height: 200 }} />
                </Box >
            </Layout >
        )
    }

}

export default Feedback;